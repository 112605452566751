// Styled list
.floatList {
    li + li {
        margin-top:1.5rem;
    }
}

// SOME HELPFUL CLASSES
//////////////////////////////

.highlight {
	color: $secondary;
	display:block;
}

.btn {
    background: $dark;
    border:none;
    color: $light;
    display: table;
    font-size:rem($h5-size);
    line-height: 1.2;
    margin-bottom:.5rem;
    margin-top:.5rem;
    padding: .8rem 1.5rem;
    text-align: center;
    text-decoration: none;
    text-transform:uppercase;
    transition: 300ms;
    margin-left: auto;

    *[class^="fa-default"], &:before {
        content:'+';
        display:inline-block;
        font-size:1.5em;
        line-height:0;
        margin-right:.5rem;
        vertical-align:middle;
	}

    .no-gutter & {
        background: $light;
        color: $primary;
        margin-left:0;
    }

    &.plain {
        background-color:transparent;
        color:$light;

        &:before {
            content:none;
        }
    }

	&[href], &[name] {
		cursor: pointer;

		&:hover, &:active, &:focus {
			background: $primary;
			color: $light;
			text-decoration:none;
		}
	}
}

.notification {
	padding: 1em;
	background: $alert;
	color: $light;
	font-size: 1.2em;
}

// PAGE WRAP
//////////////////////////////

.page-wrap {
    display:flex;
    flex-direction:column;
    padding-bottom:rem($menu-bar);

    .content {
        flex-grow:1;

        > .row {
            max-height:100%;
            flex-basis:100%;
        }
    }

    .index & {
        overflow:hidden;
        min-height:100vh;

        .content {
            display:flex;
            flex-shrink:0;
            overflow:hidden;
        }
    }

    @include breakpoint(medium) {
        padding-bottom:0;
    }

    @include breakpoint(large) {
        body:not(.cmsbackend).javascript & {
            overflow:hidden;
            height:100vh;

            .content {
                display:flex;
                // overflow:hidden;
            }
        }
    }
}

@include breakpoint(large) {
    .grid {
        bottom:0;
        display:flex;
        flex-basis:100%;
        flex-grow:0;
        flex-shrink:1;
        left:rem($base-gap);
        max-height:100%;
        overflow:hidden;
        position:absolute;
        right:rem($base-gap);
        top:0;
    }

    .row.offset {
        flex-basis:100%;
        flex-grow:0;
        flex-shrink:1;
        max-height:100%;
        overflow-x:hidden;
        overflow-y:auto;
        padding-left:calc(50vw - #{$row-max-width / 2});
        text-align:right;

        .no-gutter & {
            padding-left:0;
            padding-right:calc(50vw - #{$row-max-width / 2});
            text-align:left;
        }
    }

    .no-gutter {
        display:flex;
        position:relative;
    }
}

.col.fake {
    &-4, &-8 {
        display:flex;
        flex-grow:0;
        flex-shrink:1;
        max-height:100%;
        position:relative;
    }

    @include breakpoint(giant) {
        &-4 {
            flex-basis:calc(50% - #{$row-max-width * 0.1666});
            max-width:calc(50% - #{$row-max-width * 0.1666});
        }

        &-8 {
            max-width:calc(50% + #{$row-max-width * 0.1666 - $base-gap});
            flex-basis:calc(50% + #{$row-max-width * 0.1666 - $base-gap});
        }
    }
}

// HEADER
//////////////////////////////

.header {
    border-bottom:3px solid $light;
    box-shadow:inset 0 -1px 0 $dark;
    position:relative;
    flex-shrink:0;

    @include breakpoint(medium) {
        padding-top:1.5rem;
    }
}

// MAIN CONTENT
//////////////////////////////

.claim {
    display:table;
    font-family:$display-font;
    font-size:rem(54px);
    line-height:1;
    margin:auto;
    text-align:center;
    text-shadow:0 0 15px rgba(black, .5);

    small {
        align-items:center;
        display:flex;
        font-size:rem(15px);
        margin:auto;
        max-width:99%;
        text-transform:none;

        &:before {
            background-color:rgba($light, .5);
            content:'';
            display:block;
            flex-grow:1;
            height:1px;
            margin-right:1rem;
        }
    }

    + .btn {
        margin:3rem auto 0;
    }

    @include breakpoint(medium) {
        text-transform:uppercase;
    }
}

main {
	display: block;

	ul {
		@extend .styled-list;
	}

	.google-maps {
		@extend .video-container;

		@include breakpoint(medium) {
			height: rem(400px);
		}
	}
}

// FOOTER
//////////////////////////////

.footer {
    border-top:3px solid $light;
    box-shadow:inset 0 1px 0 $dark;
    text-transform:lowercase;
    flex-shrink:0;

    .navi {
        flex-basis:100%;
        margin-top:1.5rem;
    }

    a {
        color:inherit;
        display:block;
        padding-bottom:.25rem;
        padding-top:.25rem;
        text-decoration:none;

        &:hover,
        &:focus,
        &.active {
            color:inherit;
            text-decoration:underline;
        }
    }

    @include breakpoint(tiny) {
        .col, .navi {
            display:flex;
            justify-content:space-between;
            flex-wrap:wrap;
        }
    }

    @include breakpoint(medium) {
        justify-content:space-between;

        .navi {
            flex-basis:auto;
            flex-grow:1;
            margin:0;
            align-items:center;

            &:before, &:after {
                content:'|';
                display:block;
                flex-grow:1;
                text-align:center;
            }

            li + li {
                margin-left:1.5rem;
            }
        }

        a + a {
            order:2;
        }
    }
}

.visible {
    opacity:1 !important;
    visibility:visible !important;

    img {
        display:block;
    }

    .content {
        @extend .visible;
    }
}

// body:not(.cmsbackend).javascript {
    .slide-wrap {
        color:$light;
        display:flex;
        flex-basis:100%;
        max-height:100%;
        position:relative;
        z-index:0;

        .no-gutter & {
            max-height:100%;
        }

        &:before, .slide, .slide div, img {
            bottom:0;
            left:0;
            min-height:100%;
            min-width:100%;
            position:absolute !important;
            right:0;
            top:0;
        }

        &:before {
            background-color:rgba(black, .4);
            content:'';
            display:block;
        }

        .slide {
            z-index:-1;
        }

        .index & {
            height:auto;
        }
    }
// }

.projects {
    display:flex;
    max-height: 100%;
    position:relative;

    > .slide {
        &:before, &:after {
            background-color:rgba($light, .5);
            bottom:0;
            content:'';
            display:block;
            right:100%;
            position:absolute;
            top:0;
            width:calc(50vw - 50%);
            z-index:9;
            pointer-events:none;
        }

        &:after {
            left:100%;
            right:auto;
        }
    }
}

.project-dots {
    display:none;
    left:0;
    position:absolute;
    top:0;
    z-index:15;

    input {
        display:none;

        &:checked {
            ~ label {
                @extend .fa-minus;
            }

            ~ div {
                max-height:100px;
            }
        }
    }

    label {
        @extend .fa-plus;
        background-color:$primary;
        border:none;
        color:$light;
        cursor:pointer;
        display:table;
        height:3rem;
        line-height:3;
        padding-right:1rem;

        &:before {
            @extend .fa-default;
            background-color:$light;
            color:$primary;
            display:block;
            float:left;
            height:100%;
            line-height:inherit;
            margin-right:1rem;
            text-align:center;
            width:3rem;
        }
    }

    div {
        max-height:0;
        overflow:hidden;
        transition:300ms;
        white-space:nowrap;
    }

    a {
        display:inline-block;
    }

    img {
        max-width:80px;
    }

    @include breakpoint(large) {
        .javascript & {
            display:table;
        }
    }
}

body:not(.index):not(.kontakt) {
    .gallery + img, .lazy, [data-lazy] {
        display:none;
    }
}

.row.image-dots {
    padding:rem($base-gap * .677);

    .col {
        padding:rem($base-gap * .333);
    }

    a {
        @extend .margin-bottom;
    }
}

body:not(.cmsbackend).javascript {
    .slide {
        flex-basis:100%;
        height:100%;
        max-width:100%;
        position:relative;
        display:block;

        [class*=slick] {
            height:100%;
        }

        &.list-visible {
            &, > .slick-list {
                overflow: visible !important;
            }
        }

        .slick-list {

            > .slick-track{
                display: flex;

                > .slick-slide{
                    display: flex;
                    height: 100%;
                    position:relative;
                }
            }
        }

        .content {
            bottom:0;
            display:flex;
            flex-basis:100%;
            left:0;
            max-height:100%;
            overflow:hidden;
            position:relative;
            right:0;
            top:0;
            transition:opacity 300ms, visibility 300ms;
            z-index:2;
            width:100%;
            height:100%;

            > .row {
                max-height:100%;
                overflow-x:auto;
                overflow-y:auto;
            }
        }

        .item {
            background-position:center;
            background-repeat:no-repeat;
            overflow:hidden;
            position:relative;

            > img {
                @supports ( object-fit:cover ) {
                    bottom:0 !important;
                    height:100% !important;
                    left:0 !important;
                    object-fit:cover !important;
                    right:0 !important;
                    top:0 !important;
                    transform:none !important;
                    position:static !important;
                }
            }
        }

        button {
            background-color:transparent;
            border:none;
            cursor:pointer;
            display:inline-block;
            outline:0;
            overflow:hidden;
            position:absolute;
            transition:300ms;
            z-index:10;
        }

        @include breakpoint(large) {
            bottom:0;
            left:0;
            position:absolute;
            right:0;
            top:0;

            .content {
                background-color:rgba(black, .65);
                color:$light;
                opacity:0;
                position:absolute;
                padding-right:rem($base-gap);
            }

            .gallery + img, .lazy, [data-lazy] {
                display:block;
                opacity:0;
                transition:opacity 300ms, visibility 300ms;
            }

            .item {
                background-image:image-url('loading.gif');

                > img {
                    left:50%;
                    max-width:none;
                    min-height:100%;
                    min-width:100%;
                    position:absolute;
                    top:50%;
                    transform:translate3d(-50%, -50%, 0);
                    width:auto;
                    height:100%;
                }
            }

            .image-dots {
                a {
                    margin-bottom:0;
                }
            }
        }
    }

    .gallery {
        background-color:$light;
        bottom:0;
        left:0;
        z-index:20;
        opacity:0;
        visibility:hidden;
        pointer-events:none;

        &.visible {
            pointer-events:auto;
        }

        .slick-list {
            height:100%;
        }

        &, .closeBtn {
            position:absolute;
            right:0;
            top:0;
            transition:600ms;
        }

        .closeBtn {
            font-size:2rem;
            height:1em;
            right:rem($base-gap);
            top:rem($base-gap);
            width:1em;
            z-index:15;
        }

        path, polygon {
            fill:$light;
        }

        img {
            left:50%;
            max-width:none;
            min-height:100%;
            min-width:100%;
            position:absolute;
            top:50%;
            transform:translate3d(-50%, -50%, 0);
            width:auto;
            z-index:-1;
        }
    }
}

body.iexplore {
    .slide {
        .content > .row {
            overflow-y:scroll !important;
        }
    }
}

[class*=slick-arrow] {
    bottom:0;
    font-size:rem(50px);
    height:3.448em !important;
    margin:auto;
    opacity:0;
    padding:0 .5em;
    top:0;
    width:2em;

    &:hover, &:focus {
        outline:0;
        opacity:1;
    }

    &.slick-prev {
        background-position:left center;
        right:100%;
        transform:translate3d(100%, 0, 0);

        .list-visible > & {
            transform:translate3d(0, 0, 0);
        }
    }

    &.slick-next {
        background-position:right center;
        left:100%;
        transform:translate3d(-100%, 0, 0);

        .list-visible > & {
            transform:translate3d(0, 0, 0);
        }
    }
}

// PRELOADER
//////////////////////////////
.preloader {
    align-items:center;
    animation-delay:4s;
    animation-duration:2000ms;
    animation-fill-mode:forwards;
    animation-name:preloader;
    background-color:$light;
    bottom:0;
    display:flex;
    height:100%;
    left:0;
    position:fixed;
    right:0;
    top:0;
    z-index:999;

    i {
        animation-duration:1000ms;
        animation-fill-mode:forwards;
        animation-name:grow;
        animation-timing-function:ease-out;
        background-color:$primary;
        display:block;
        height:1px;
        width:0;
    }

    .javascript & {
        animation-delay:.5s;
    }
}

@include keyframes(preloader) {
    0% {
        opacity:100%;
        visibility:visible;
    }

    100% {
        animation-play-state:paused;
        opacity:0;
        pointer-events:none;
        visibility:hidden;
    }
}

@include keyframes(grow) {
    0% {
        width:0;
    }

    100% {
        width:100%;
    }
}
.datenschutzerklaerung a {
    word-wrap:break-word;
}

#counter_phone {
    bottom: 400px !important;
}