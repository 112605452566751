// Use this file for your custom mixins/functions

::-webkit-scrollbar-thumb:vertical {
    width:rem($base-font-size * .5);
    background:rgba(black, .5);
}

::-webkit-scrollbar-thumb:horizontal {
    width:rem($base-font-size * .5);
    background:rgba(black, .5);
}

::-webkit-scrollbar {
    width:rem($base-font-size * .5);
    background:rgba($medium, .5);
}

/*
    extend styles in _page
*/
// .svg {
//     display:inline-block;
//     font-style:normal;
//     height:1em;
//     line-height:0;
//     overflow:hidden;
//     vertical-align:text-bottom;
//     white-space:nowrap;
//     width:1em;

//     &.fbook:before {
//         background-image:image-url('fbook-white.svg');
//     }

//     &:before {
//         background-position:center 0;
//         background-repeat:no-repeat;
//         background-size:contain;
//         content:'';
//         display:inline-block;
//         font-size:.8em;
//         height:1em;
//         line-height:.8;
//         vertical-align:top;
//         width:1em;
//     }
// }

/* vertical sectioning */
$class: 'rhythm';
$base: 3vh;

$alert:     #d43f3a;
$success:   #4cae4c;
$notify:    #eea236;

.#{$class} {
    margin-bottom:#{$base};
}

.page-#{$class} {
    padding-bottom:#{$base};
    padding-top:#{$base};

    &-small {
        padding-bottom:1.5rem;
        padding-top:1.5rem;
    }
}

.nojs {
    #googleMaps, .googleMaps {
        display:none !important;
    }
}

.branding {
    display:table;
    float:none;
    margin:0 auto;
    max-width:100%;
    padding:0;
    position:relative;
    text-decoration:none;
}

/*
    extend styles in _globals
*/

/*
    fork from CSS-Tricks.com with indexing on mobile added
*/
th, .thead {
    text-align:left;
    font-weight:bold;
}

table {
    display:table;

    h1, h2, h3, h4, h5, h6 {
        margin-bottom:0;
        font-size:rem($h6-size);
    }

    // tr {
    //     border-bottom:1px solid rgba($medium, .25);
    //     padding:rem($base-gap) .5rem;

    //     &:nth-child(2n+1) {
    //         background-color:rgba(white, .5);
    //     }
    // }

    // td {

    //     &:first-child {
    //         span {
    //             display:block;
    //             font-size:.9em;
    //             font-style:italic;
    //             font-weight:normal;
    //         }
    //     }

    //     &:before {
    //         content:none;
    //     }
    // }
}

// .horizontal {
//     tr {
//         counter-reset:tdcount;

//         &:first-child {
//             font-weight:bold;
//         }
//     }

//     td, th {
//         counter-increment:tdcount;
//         position:relative;
//         padding-left:2rem;

//         &:first-child {
//             font-weight:inherit;
//         }

//         &:before {
//             content:counter(tdcount) ". ";
//             left:rem(5px);
//             position:absolute;
//             text-overflow: ellipsis;
//             top:auto;
//             white-space:nowrap;
//             width:2rem;
//         }
//     }
// }

.table {
    @extend table;
    display:table;

    &-row {
        @extend tr;
        display:table-row;
    }

    &-head {
        @extend th;
        display:table-cell;
    }

    &-cell {
        @extend td;
        display:table-cell;
    }
}

body {
    dl.floatList {
        dt {
            font-weight:bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

@include breakpoint(medium) {
    ul.floated {
        display:flex;
        flex-wrap:wrap;

        li {
            flex-basis:auto;
            width:calc(50% - #{$base-gap});

            &:nth-child(odd) {
                margin-right:rem($base-gap * 2);
            }

            &:nth-child(2) {
                border-top:none;
            }
        }
    }

    .page-#{$class} {
        padding-bottom:#{$base * 1.5};
        padding-top:#{$base * 1.5};
    }
}

@include breakpoint(large) {
    main {
        /* remove counter reset */
        table, .horizontal {
            tr {
                padding:0;
            }

            td, th {
                padding-top:rem($base-gap);
                padding-bottom:rem($base-gap);

                &:before {
                    content:none
                }
            }
        }
    }
}

@media screen and (max-width:map-get($breakpoints, medium) - em(1px)) {
    div[class^='page-navi'] {
        align-items:inherit;
        display:flex;
        flex-direction:column;
        flex-wrap:initial;
        flex:1;
        color:$light;
        height:auto;

        &.row {
            margin-left:0;
            width:100%;
        }

        .col, nav {
            flex-basis:auto;
            flex-grow:0;
            padding:1.5rem 6vw;
        }

        .navi {
            padding:0;
            margin-bottom:1.5rem;

            a, span {
                padding-bottom:1.2rem;
                padding-top:1.25rem;
                text-decoration:none;
                line-height:1.2;
                border-bottom:1px solid rgba($light, .15);
            }

            .sub {
                opacity:.8;
                padding-left:.5rem;
                border-left:1px solid rgba($light, .15);

                li {
                    padding-left:rem($base-gap) * 1.5;
                    text-indent:-1rem;
                }

                a {
                    &:before {
                        content:'\203A';
                        display:inline-block;
                        text-indent:0;
                        width:rem($base-gap);
                    }
                }
            }

            li {
                padding:0;
            }
        }
    }

    .scrollable {
        background-attachment:scroll, scroll;
        background-image:-ms-radial-gradient(top, ellipse, rgba(white, .3) 0%, rgba(255,255, 255, 0) 75%);
        background-image:radial-gradient(top, ellipse, rgba(white, .3) 0%, rgba(255,255, 255, 0) 75%);
        background-image:-webkit-radial-gradient(top, ellipse, rgba(white, .3) 0%, rgba(255,255, 255, 0) 75%);
        background-position:center 0;
        background-repeat:no-repeat;
        background-size:90% 10px;
        flex:1 1 auto !important;
        height:1px;
        overflow-x:hidden;
        overflow-y:auto;
        position:relative;

        &:before {
            background-image:linear-gradient(to bottom, $primary 50%, rgba(255, 255, 0, 0) 100%);
            content:'';
            display:block;
            height:20px;
            max-width:100%;
            position:absolute;
            right:0;
            top:0;
            width:100%;
        }
    }

    .page-wrap {
        left:0;
        position:relative;
        transition:600ms;
    }

    /* states for toggled navi */
    #navi-toggled:checked {

        ~ * .page-navi, ~ .page-navi {

            .navi li {
                animation-name:fadeItems;
                animation-duration:1s;
            }
        }

        ~ * .page-wrap, ~ .page-wrap {
            left:map-get($breakpoints, 'tiny') * -0.66;
            overflow:hidden;
            position:relative;
        }
    }
}


@mixin keyframes($name) {
    @-webkit-keyframes #{$name} { @content }
    @-moz-keyframes #{$name} { @content }
    @-o-keyframes #{$name} { @content }
    @keyframes #{$name} { @content }
}

@include keyframes(fadeItems) {
    0% {
        transform:translateX(50%);
    }

    100% {
        transform:translateX(0);
    }
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $from-color, $to-color) {
    background-image: -webkit-gradient(to $direction, 0% 0%, 0% 100%, from($from-color), to($to-color));
    background-image: -webkit-linear-gradient($direction, $from-color, $to-color);
    background-image:    -moz-linear-gradient($direction, $from-color, $to-color);
    background-image:     -ms-linear-gradient($direction, $from-color, $to-color);
    background-image:      -o-linear-gradient($direction, $from-color, $to-color);
}