// Form styles
form.default {
    overflow: hidden;
    width: 100%;

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $dark;
    }

    ::-moz-placeholder { /* Firefox 19+ */
        color: $dark;
    }

    :-ms-input-placeholder { /* IE 10+ */
        color: $dark;
    }

    :-moz-placeholder { /* Firefox 18- */
        color: $dark;
    }

    legend {
        height:3rem;
        margin-bottom:.5rem;
        border-bottom:1px solid rgba($medium, .5);
        font-weight:bold;
        font-size:1.2rem;
    }

    fieldset {
        border:none;
        margin:0;

        > div {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            textarea {
                display: flex;
                align-items: flex-start;
                flex-grow: 1;
            }
        }
    }

    label, input, textarea, select, button, [type="submit"] {
        min-height: 3rem;
    }

    label {
        color: inherit;
        display: none;
        padding: 1rem 0 0.6rem;
        position:relative;

        small {
            color: $alert;
            display:inline-block;
        }

        &[for="Datenschutz"] {
            float:right;
            display:block;
            max-width:85%;
            margin-bottom:.875rem;
        }

        &.error {
            -webkit-animation: error 1s;
            animation: error 1s;
            background: $alert;
            color: $light;
            display:block;
            font-size: 0.9rem;
            margin: 0 0 0.5rem 0;
            max-width:none;
            padding-left: rem($base-gap);
            padding-right: rem($base-gap);
            position: relative;
            text-align: center;
            width: 100%;

            @include breakpoint(tiny) {
                font-size: 1rem;
            }

            &:before {
                border-bottom: rem(10px) solid $alert;
                border-left: rem(7px) solid transparent;
                border-right: rem(7px) solid transparent;
                border-top: 0;
                content: "";
                height: 0;
                position: absolute;
                right: rem(10px);
                top: rem(-10px);
                width: 0;
            }
        }
    }

    [type="text"], [type="tel"], [type="email"], [type="file"], input, textarea, select {
        background-color:$light;
        border: 1px solid rgba($secondary, .5);
        color: $dark;
        display: block;
        font-family: $main-font;
        font-size: 1rem;
        line-height: rem($base-line-height);
        margin: 0 0 0.5rem 0;
        padding: 0.8rem;
        position: relative;
        resize: none;
        transition: border .3s;
        width:100%;

        &:focus {
            background-color:rgba($dark, .33);
            outline: none;
        }
    }

    textarea { /* fake line-height */
        min-height:4.5rem;
        padding-top:0.9rem;
    }

    [type="checkbox"] {
        background-color:transparent;
        display: block;
        float: right;
        margin: .2rem 0.75rem 0.5rem 0;
        width: 15px;
    }

    [type="radio"] {
        background-color:transparent;
        clear: none;
        float: left;
        margin: .4rem .8rem 0 0;
        min-height: auto;
        width: 15px;
    }

    button, [type="submit"] {
        @extend .btn;
        width:100%;
        font-family:$main-font;
    }

    .errorContainer {
        position: relative;
    }
}

/*=VALIDATION */

@keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.specialfield {
    display: none !important;
    visibility:hidden !important;
}

#newsletter-info {
    @extend input;
    font-weight:bold;
}

img[name=vimg] + input[name=imgverify] {
    float:right;
}