// Icons
@include font-face('FontAwesome',
    'fontawesome-webfont.woff',
    'fontawesome-webfont.ttf',
    'fontawesome-webfont.svg',
    'fontawesome-webfont.eot'
);

/* This stylesheet generated by Transfonter (https://transfonter.org) on September 4, 2017 2:18 PM */
@include font-face('Raleway',
    'Raleway.woff',
    'Raleway.ttf',
    'Raleway.svg',
    'Raleway.eot',
    $weight: normal
);

@include font-face('Raleway',
    'Raleway-Bold.woff',
    'Raleway-Bold.ttf',
    'Raleway-Bold.svg',
    'Raleway-Bold.eot',
    $weight: bold
);

@include font-face('BausteinSmall',
    'BausteinSmallNormal.woff',
    'BausteinSmallNormal.ttf',
    'BausteinSmallNormal.svg',
    'BausteinSmallNormal.eot'
);