// Main Navigation
//////////////////////////////

nav {
    position:relative;

    > button {
        background-color:transparent;
        border-color:rgba($medium, .5);
        border-style:solid;
        border-width:1px 0 0 0;
        display:none;
        font-family:$main-font;
        font-size:rem($h4-size);
        margin:rem($base-gap * 2) auto 0;
        padding:rem($base-gap * .5) rem($base-gap * 2);
        text-transform:lowercase;

        &:after {
            @extend .fa-default;
            content:'\f107';
            display:inline-block;
            font-size:rem($h4-size);
            margin-left:.5rem;
        }

        &:hover,
        &:focus {
            outline:none;
        }
    }

    .navi {
        > li {
            text-transform:uppercase;

            a {
                color:$light;
                position:relative;
                text-decoration:none;
                transition:600ms;

                &:hover,
                &:focus,
                &.active {
                    color:$secondary;
                    text-decoration:underline;
                }
            }

            a, span {
                display:block;
            }
        }
    }

    @include breakpoint(medium) {
        > button {
            display:table;
        }

        li {
            &.startseite, &.kontakt {
                display:none;
            }

            &.hasSub {
                > a, > span {
                    color:$secondary;
                    font-size:rem($h5-size);
                }
            }
        }

        .navi {
            background-color:$primary;
            box-shadow:0 5px 10px rgba(black, .15);
            display:table;
            left:50%;
            opacity:0;
            padding:rem($base-gap * 1.5) rem($base-gap * 2);
            position:absolute;
            text-align:center;
            top:110%;
            transform:translate3d(-50%, 0, 0);
            transition:300ms;
            visibility:hidden;
            z-index:50;

            > li:not(.ausschreibungen) {
                &:after {
                    background-color:$light;
                    content:'';
                    display:block;
                    height:1px;
                    margin:1rem auto;
                    width:20px;
                }
            }
        }

        &:hover, button:focus ~, button:hover ~ {
            .navi {
                opacity:1;
                top:100%;
                visibility:visible;
            }
        }
    }
}

// Mobile Menu Bar
//////////////////////////////

$menu-bar: 50px;

@media screen and (max-width:map-get($breakpoints, medium) - em(1px)) {
    .page-navi {

        // Off Canvas Navigation
        //////////////////////////////

        background:$primary;
        bottom:rem($menu-bar);
        left:100%;
        max-width:map-get($breakpoints, tiny) * .9 !important;
        overflow-y: auto;
        position: fixed;
        top: 0;
        transition:600ms;
        width:100%;
        z-index: 895;
    }

    .mobile-bar {
        align-items:center;
        background:$light;
        border-top:1px solid #ddd;
        display: flex;
        height:rem($menu-bar);
        justify-content:space-between;
        left:0;
        position: fixed;
        right:0;
        bottom:0;
        z-index: 894;

        li {
            display:flex;
            padding:0 rem($base-gap);
        }

        a {
            color:inherit;
            text-decoration:none;
        }

        :before {
            color:$secondary;
        }
    }
}

// Desktop Navigation
//////////////////////////////
@include breakpoint(medium) {

    .mobile-bar, .page-navi h2 {
        display:none;
    }
}

label[for="navi-toggled"] {
    @extend .fa-bars;
    flex-direction:row-reverse;

    &:before {
        @extend .fa-default;
    }

    &:after {
        background:rgba(black, 0.8);
        bottom:rem($menu-bar);
        content:'';
        opacity: 0;
        pointer-events:none;
        position: fixed;
        right:0;
        top:0;
        transition:opacity .6s;
        width: 100%;
        z-index:-1;
    }
}

#navi-toggled {
    display: none;

    &:checked {

        ~ * label[for="navi-toggled"] {
            @extend .fa-close;

            &:after {
                opacity: 1;
                pointer-events:auto;
            }
        }

        ~ * .page-navi,
        ~ .page-navi {
            transform:translate3d(-100%, 0, 0);
        }
    }
}