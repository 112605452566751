/**
* Lightcase - jQuery Plugin
* The smart and flexible Lightbox Plugin.
*
* @author		Cornel Boppart <cornel@bopp-art.com>
* @copyright	Author
*
* @version		2.3.4 (29/12/2015)
*/

$lcase-breakpoint: 'medium';
$lcase-max-width:  map-get($breakpoints, $lcase-breakpoint) - 0.1em;
$lcase-min-width:  map-get($breakpoints, $lcase-breakpoint);

@mixin icon($color: #ccc) {
    position: fixed;
    z-index: 9999;
    width: 1.123em;
    height: auto;
    font-size: rem($base-font-size * 2);
    line-height: 1;
    text-align: center;
    text-shadow: none;

    &, &:focus {
        text-decoration: none;
        color: $color;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        transition: color, opacity, ease-in-out 0.25s;
    }

    & > span {
        display: inline-block;
        text-indent: -9999px;
    }

    // Default hover status
    &:hover {
        color: $light;
        // text-shadow: 0 0 15px $light;
    }
}

/**
* Provides natural content overflow behavior and scrolling support
* even so for touch devices.
*
* Example usage:
* @include overflow()
*/
@mixin overflow() {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

/**
* Neutralizes/resets dimensions including width, height, position as well as margins,
* paddings and styles. Used to enforce a neutral and unstyled look and behavoir!
*
* Example usage:
* @include clear(true)
*
* @param boolean $important
*/
@mixin clear($important) {
    @if $important {
        $important: !important;
    }

    position: relative $important;
    top: auto $important;
    left: auto $important;
    width: auto $important;
    height: auto $important;
    margin: 0 $important;
    padding: 0 $important;
    border: none $important;
    background: none $important;
}

[class*='lightcase-icon-']:before {
    font-family: $icon-font, sans-serif;
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

/* Codes */
.lightcase-icon-play:before {
    @extend .fa-play-circle;
}
.lightcase-icon-pause:before {
    @extend .fa-pause-circle;
}
.lightcase-icon-close:before {
    @extend .fa-times-circle;
}
.lightcase-icon-prev:before {
    @extend .fa-chevron-circle-left;
}
.lightcase-icon-next:before {
    @extend .fa-chevron-circle-right;
}
.lightcase-icon-spin:before {
    @extend .fa-spinner;
}

#lightcase-case {
    display: none;
    position: fixed;
    z-index: 2002;
    top: 50%;
    left: 50%;
    font-family: arial, sans-serif;
    font-size: 13px;
    line-height: 1.5;
    text-align: left;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

    // Not type: image, video, flash or error
    &:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) {
        @media screen and (max-width: $lcase-max-width) {
            @include clear(true);
        }
    }
}

#lightcase-content {
    // Not type: error
    #lightcase-case:not([data-lc-type=error]) & {
        @media screen and (min-width: $lcase-min-width) {
            position: relative;
            z-index: 1;
            overflow: hidden;
            text-shadow: none;
            background-color: rgba($dark, .5);
            -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
            -o-box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
            -webkit-backface-visibility: hidden;
        }
    }

    // Type: image + video
    #lightcase-case[data-lc-type=image] &,
    #lightcase-case[data-lc-type=video] & {
        @media screen and (min-width: $lcase-min-width) {
            background-color: rgba($dark, .5);
        }
    }

    // Not type: image, video, flash or error
    #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) & {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        -o-box-shadow: none;
        box-shadow: none;

        @media screen and (max-width: $lcase-max-width) {
            @include clear(true);
        }

        .lightcase-contentInner {
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            -o-box-sizing: border-box;
            box-sizing: border-box;

            @media screen and (max-width: $lcase-max-width) {
                padding: 15px;

                &, & > * {
                    width: 100% !important;
                    max-width: none !important;
                }

                // Set auto height to each element except iframes, because of that
                // those cannot have a natural/native height and it must be especially defined.
                & > *:not(iframe) {
                    height: auto !important;
                    max-height: none !important;
                }
            }
        }
    }

    .lightcase-contentInner {
        @media screen and (max-width: $lcase-max-width) {
            // For mobile (touch) devices
            .lightcase-isMobileDevice #lightcase-case[data-lc-type=iframe] & {
                @include overflow();
            }
            // Type: image + video
            [data-lc-type=image] &,
            [data-lc-type=video] & {
                @media screen and (min-width: $lcase-min-width) {
                    line-height: 0.75;
                }
            }
        }

        // Note: This is a very special wrap only added for few
        // types of content, type 'inline' and 'ajax'.
        .lightcase-inlineWrap {
            // For mobile (touch) devices
            .lightcase-isMobileDevice #lightcase-case[data-lc-type=iframe] & {
                @include overflow();
            }

            // Not type: image, video, flash or error
            #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) & {
                @media screen and (max-width: $lcase-max-width) {
                    @include clear(true);
                }
            }

            // Not type: error
            #lightcase-case:not([data-lc-type=error]) & {
                background-color: $light;

                @media screen and (min-width: $lcase-min-width) {
                    padding: 30px;
                    overflow: auto;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    -o-box-sizing: border-box;
                    box-sizing: border-box;
                }
            }

            div {
                padding-bottom:0.1em;
            }
        }
    }

    // Content - Extend with further selectors and colors in case you have
    // elements like <ul>, <li>, <span> or other as part of content within lightcase.
    * {
        @media screen and (max-width: $lcase-max-width) {
            color: $light;
        }

        @media screen and (min-width: $lcase-min-width) {
            color: $dark;
        }
    }

    img {
        width:auto;
    }
}
#lightcase-case {

    p.lightcase-error {
        margin: 0;
        font-size: 17px;
        text-align: center;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        color: #aaa;

        @media screen and (max-width: $lcase-max-width) {
            padding: 30px 0;
        }

        @media screen and (min-width: $lcase-min-width) {
            padding: 0;
        }
    }
}
// Styles and overrides for all elements out of the lightcase scope
.lightcase-open {

    body {
    // For mobile (touch) devices
    .lightcase-isMobileDevice & {
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;
    }

    @media screen and (max-width: $lcase-max-width) {
        padding: 55px 0 70px 0;

        & > *:not([id*='lightcase-']) {
            position: fixed !important;
            top: -9999px !important;

            width: 0 !important;
            height: 0 !important;

            overflow: hidden !important;
        }
    }
}

}
#lightcase-info {
    // You can change to position "static" or "relative" instead, but consider
    // that this height will be integrated and calculated
    // to the maximum height additionally to the image height.
    position: absolute;
    padding-top: 15px;
    padding-left:15px;
    padding-right:15px;
    left:0;
    right:0;

    #lightcase-title,
    #lightcase-caption {
        margin: 0;
        padding: 0;

        line-height: 1.5;
        font-weight: normal;
        text-overflow: ellipsis;
    }

    #lightcase-title {
        font-size: rem($base-font-size);
        color: $light;
    }

    #lightcase-caption {
        clear: both;
        font-size: rem(13px);
        color: rgba($light, .8);

        &:not(:empty) {
            border-left:2px solid rgba($light, .4);
            padding-left:10px;
            margin-top:10px;
        }
    }

    #lightcase-sequenceInfo {
        font-size: rem(13px);
        color: rgba($light, .8);
        position: fixed;
        top: 15px;
        left: 0;
        max-width: 87.5%;
        padding: 5px 15px;
        background: rgba($dark, .8);
    }

    @media screen and (max-width: $lcase-max-width) {
        // Not type: image, video, flash or error
        #lightcase-case:not([data-lc-type=image]):not([data-lc-type=video]):not([data-lc-type=flash]):not([data-lc-type=error]) & {
            position: static;
        }
    }
}
#lightcase-loading {
    @include icon();

    position: fixed;
    z-index: 2001;
    top: 50%;
    left: 50%;

    margin-top: -0.5em;
    margin-left: -0.5em;

    opacity: 1;

    font-size: 32px;

    // text-shadow: 0 0 15px #fff;

    // Fixes the icon wobble issue in firefox
    -moz-transform-origin: 50% 53%;

    -webkit-animation: fa-spin 0.5s infinite linear;
    -moz-animation: fa-spin 0.5s infinite linear;
    -o-animation: fa-spin 0.5s infinite linear;
    animation: fa-spin 0.5s infinite linear;
}
#lightcase-nav {

    // Generic styles for all navigation links
    a[class*='lightcase-icon-'] {
        @include icon();
        outline: none;
        cursor: pointer;

        // Hover status for mobile (touch) devices
        // .lightcase-isMobileDevice & {
        //     &:hover {
        //         color: #aaa;
        //         text-shadow: none;
        //     }
        // }

        // Prev button
        &.lightcase-icon-prev {
            left: 15px;
        }

        // Next button
        &.lightcase-icon-next {
            right: 15px;
        }

        // Play/Pause button
        &.lightcase-icon-pause,
        &.lightcase-icon-play {
            left: 50%;
            margin-left: -0.5em;

            @media screen and (min-width: $lcase-min-width) {
                opacity: 0;
            }
        }

        // Close button
        &.lightcase-icon-close {
            top: 15px;
            right: 15px;
            bottom: auto;
            margin: 0;
            opacity: 0;
            outline: none;

            @media screen and (max-width: $lcase-max-width) {
                position: absolute;
            }

            @media screen and (min-width: $lcase-min-width) {
                position: fixed;
            }
        }

        @media screen and (max-width: $lcase-max-width) {
            bottom: 15px;
            font-size: 24px;
        }

        @media screen and (min-width: $lcase-min-width) {
            bottom: 50%;
            margin-bottom: -0.5em;

            &:hover,
            #lightcase-case:hover ~ & {
                opacity: 1;
            }
        }
    }

}
#lightcase-overlay {
    display: none;
    width: 100%;
    height: 100%;

    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;

    background: rgba(black, .8);

    @media screen and (max-width: $lcase-max-width) {
        opacity: 1 !important;
    }
}